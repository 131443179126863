import { zodResolver } from '@hookform/resolvers/zod'
import assert from 'assert'
import { cloneDeep, groupBy } from 'lodash'
import React, { PropsWithChildren, createContext, useContext, useEffect, useState } from 'react'
import { FieldError, FieldValues, UseFormReturn, useForm } from 'react-hook-form'
import { v4 as uuid } from 'uuid'
import { z } from 'zod'
import {
  BasicField,
  Condition,
  ConditionTargetEnableRequire,
  ConditionTargetShowHide,
  FormField,
  FormFieldPages,
  FormFieldTypes,
  Page,
} from '../interfaces'
import { FormStateStore } from '../shared'
import { FormRendererUtils, GeneralUtils, GroupedPageUtils, ValidationUtils } from '../utils'

export type CustomFieldError = FieldError & { customError: boolean }

interface FormStateContextType {
  pages: Page[]
  setPages: (pages: Page[]) => void
  fieldPagesSOT: FormFieldPages
  setFieldPagesSOT: (fieldPages: FormFieldPages) => void
  fieldPages: FormFieldPages
  setFieldPages: (fieldPages: FormFieldPages) => void
  formFields: FormField[]
  setFormFields: (formFields: FormField[]) => void
  latestFormData: { [key: string]: any }
  page?: string
  setPage: (page: string) => void
  validateForm: (pageGuid: string) => Promise<string[]>
  isPrintMode: boolean
  setIsPrintMode: (isPrintMode: boolean) => void
  isReadOnly: boolean
  setIsReadOnly: (isReadOnly: boolean) => void
  isLoading: boolean
  setIsLoading: (isLoading: boolean) => void
  updateValidationSchema: () => void
  newFields: FormField[]
  hiddenPageIds: string[]
  setHiddenPageIds: (hiddenPageIds: string[]) => void
  fieldPagesLocalRef: FormFieldPages
  onUpdateFormStructure: (updatedPages: FormFieldPages) => void
  conditions: Condition[]
  setConditions: (conditions: Condition[]) => void
  scrollFieldIntoView: (fieldId: string) => void
  onAddEntryToGroupedPage: (
    activePage: Page,
    latestFieldPages: FormFieldPages | null,
    listIndex?: number,
  ) => FormFieldPages
  onRemoveEntryFromGroupedPage: (activePage: Page, index: number) => void
  sortedPages: Page[]
  /**
   * React hook form methods
   */
  formMethods: UseFormReturn<FieldValues, any, undefined>
}

const FormStateContext = createContext<FormStateContextType | undefined>(undefined)

export const FormStateProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const [fieldPages, setFieldPages] = useState<FormFieldPages>({})
  const [fieldPagesSOT, setFieldPagesSOT] = useState<FormFieldPages>({}) // Source of truth. Should not be modified.
  const [formFields, setFormFields] = useState<FormField[]>([])
  const [page, setPage] = useState<Page['guid']>()
  const [pages, setPages] = useState<Page[]>([])
  const [isPrintMode, setIsPrintMode] = useState<boolean>(false)
  const [isReadOnly, setIsReadOnly] = useState<boolean>(false)

  const [conditions, setConditions] = useState<Condition[]>([])
  const [hiddenPageIds, setHiddenPageIds] = useState<string[]>([])
  const [newFields, setNewFields] = useState<FormField[]>([])
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [zodValidationSchema, setZodValidationSchema] = useState<any>(z.object({}))

  const sortedPages = GeneralUtils.sortPages(pages)

  FormStateStore.fieldPagesLocalRef = fieldPages

  useEffect(() => {
    updateValidationSchema()
  }, [page])

  const updateValidationSchema = () => {
    if (!page) {
      return
    }

    const allFieldsUntilCurrentPage: FormField[] = getAllFieldsUntilPage(page)
    setZodValidationSchema(z.object(ValidationUtils.initializeValidationSchema(allFieldsUntilCurrentPage)))
  }

  const formMethods = useForm<Record<string, any>>({
    resolver: isReadOnly ? undefined : zodResolver(zodValidationSchema),
    criteriaMode: 'all',
    defaultValues: {},
  })

  const { watch } = formMethods

  const latestFormData = watch()

  const onUpdateFormStructure = (updatedPages: FormFieldPages) => {
    FormStateStore.fieldPagesLocalRef = updatedPages
    setFieldPages(updatedPages)
    page && setFormFields(updatedPages[page] ?? [])
  }

  const getAllFieldsUntilPage = (pageGuid: string): FormField[] => {
    const previousPagesTilPage = sortedPages.slice(0, sortedPages.findIndex(p => p.guid === pageGuid) + 1)

    const visiblePages =
      groupBy(previousPagesTilPage, page => (hiddenPageIds.includes(page.guid) ? 'hidden' : 'visible'))['visible'] ?? []

    return visiblePages.flatMap(page => FormStateStore.fieldPagesLocalRef[page.guid])
  }

  /** Returns an array of paths that failed zod validation */
  const validateForm = async (pageGuid: string): Promise<string[]> => {
    const validation = z.object(ValidationUtils.initializeValidationSchema(getAllFieldsUntilPage(pageGuid)))
    try {
      validation.parse(latestFormData)
      return []
    } catch (error: any) {
      console.error('Validation error:', error.errors)

      const paths = error.errors.flatMap((err: any) => err.path)
      return paths
    }
  }

  const onAddEntryToGroupedPage = (
    activePage: Page,
    latestUpdatedFields: FormFieldPages | null,
    listIndex?: number,
  ) => {
    const latestFieldPages = latestUpdatedFields ?? fieldPages
    const latestFieldPagesSOT = latestUpdatedFields ?? fieldPagesSOT
    const baseFields = latestFieldPagesSOT[activePage.guid]?.filter(f => f.listIndex === 0) ?? []
    const currentLength =
      listIndex !== undefined ? listIndex : GroupedPageUtils.getListIndexCount(fieldPages[activePage.guid] ?? [])
    const newFields: FormField[] = []
    const baseFieldIdMapping = {}
    const newFieldIdMapping = {}

    const updateIndexedJsonpath = (jsonPath: string) => {
      return jsonPath.replace(`${activePage.parentJsonpath}[0]`, `${activePage.parentJsonpath}[${currentLength}]`)
    }

    baseFields.forEach((baseField: FormField) => {
      const newId = uuid()

      const newField = {
        ...cloneDeep(baseField),
        guid: newId,
        key: newId,
        layout: { ...baseField.layout, i: newId },
        listIndex: currentLength,
        baseFieldId: baseField.guid,
      }

      if ((baseField as BasicField).defaultValue) {
        formMethods.setValue(newId, (baseField as BasicField).defaultValue)
      }

      if ((newField as BasicField).jsonPath) {
        ;(newField as BasicField).jsonPath = updateIndexedJsonpath((newField as BasicField).jsonPath)

        if (newField.type === FormFieldTypes.Address) {
          newField.cityJsonPath = updateIndexedJsonpath(newField.cityJsonPath)
          newField.countryJsonPath = updateIndexedJsonpath(newField.countryJsonPath)
          newField.postalCodeJsonPath = updateIndexedJsonpath(newField.postalCodeJsonPath)
          newField.streetAddressLine2JsonPath = updateIndexedJsonpath(newField.streetAddressLine2JsonPath)
          newField.streetAddressJsonPath = updateIndexedJsonpath(newField.streetAddressJsonPath)
          newField.stateProvinceJsonPath = updateIndexedJsonpath(newField.stateProvinceJsonPath)
        }
      }

      baseFieldIdMapping[newId] = baseField.guid
      newFieldIdMapping[baseField.guid!] = newId
      newFields.push(newField)
    })

    newFields
      .filter(f => f.type === FormFieldTypes.APILookup)
      .forEach((f: FormField) => {
        assert(f.type === FormFieldTypes.APILookup)

        f.queryParams?.forEach(param => {
          param.targetFormFieldId = newFieldIdMapping[param.targetFormFieldId]
        })

        f.lookupMapping?.forEach(mapping => {
          mapping.targetFormFieldId = newFieldIdMapping[mapping.targetFormFieldId]
        })
      })

    FormStateStore.fieldPagesLocalRef = {
      ...latestFieldPages,
      [activePage.guid]: [...latestFieldPages[activePage.guid], ...newFields],
    }
    setFieldPagesSOT({
      ...latestFieldPagesSOT,
      [activePage.guid]: [...latestFieldPagesSOT[activePage.guid], ...newFields],
    })
    setFieldPages(FormStateStore.fieldPagesLocalRef)
    setFormFields([...fieldPages[activePage.guid], ...newFields])

    /**
     * Assign new conditions for the new fields based on the base fields' conditions.
     */
    const baseFieldIds: string[] = Object.keys(newFieldIdMapping)
    const newConditions: Condition[] = []

    conditions.forEach(condition => {
      newFields.forEach(newField => {
        const newCondition: Condition = {
          ...condition,
          guid: uuid(),
          groupedPageId: activePage.guid,
          listIndex: currentLength,
        }
        let hasNewChange = false

        const isBaseFieldInSource = condition.source.some(s => s.formFieldId === baseFieldIdMapping[newField.guid!])
        const isAnyOfTheBaseFieldsAreInSource = condition.source.some(s =>
          baseFieldIds.some(baseFieldId => baseFieldId === s.formFieldId),
        )

        const isBaseFieldInTargetCondition = condition.target.some(t =>
          (t as ConditionTargetEnableRequire | ConditionTargetShowHide)?.targetFieldIds?.includes(
            baseFieldIdMapping[newField.guid!],
          ),
        )

        if (isBaseFieldInSource) {
          newCondition.source = condition.source.map(s =>
            baseFieldIds.includes(s.formFieldId) ? { ...s, formFieldId: newFieldIdMapping[s.formFieldId] } : s,
          )

          newCondition.target = condition.target.map(t => ({
            ...t,
            targetFieldIds: (t as ConditionTargetEnableRequire | ConditionTargetShowHide).targetFieldIds.map(tfId =>
              baseFieldIds.includes(tfId) ? newFieldIdMapping[tfId] : tfId,
            ),
          }))

          hasNewChange = true
        } else if (isBaseFieldInTargetCondition && !isAnyOfTheBaseFieldsAreInSource) {
          condition.target.forEach(t =>
            (t as ConditionTargetEnableRequire | ConditionTargetShowHide).targetFieldIds.push(newField.guid!),
          )
        }

        if (hasNewChange) {
          newConditions.push(newCondition)
        }
      })
    })

    setConditions(currentConditions => [...currentConditions, ...newConditions])
    setNewFields(newFields)

    return FormStateStore.fieldPagesLocalRef
  }

  const onRemoveEntryFromGroupedPage = (activePage: Page, index: number) => {
    if (!fieldPages[activePage.guid]) {
      return
    }

    const filteredFields = fieldPages[activePage.guid].filter(f => f.listIndex !== index) ?? []
    const filteredFieldsSOT = fieldPagesSOT[activePage.guid].filter(f => f.listIndex !== index) ?? []

    const updateIndexedJsonpath = (jsonPath: string, oldIndex: number, newIndex: number) => {
      return jsonPath.replace(`${activePage.parentJsonpath}[${oldIndex}]`, `${activePage.parentJsonpath}[${newIndex}]`)
    }

    // Decrement jsonPath for grouped fields after the removed entry.
    const updatedFields = filteredFields.map(f => {
      if (f.listIndex > index) {
        const newIndex = f.listIndex - 1
        const updatedField = {
          ...f,
          listIndex: newIndex,
        }

        if ((updatedField as BasicField).jsonPath) {
          ;(updatedField as BasicField).jsonPath = updateIndexedJsonpath(
            (updatedField as BasicField).jsonPath,
            f.listIndex,
            newIndex,
          )

          if (updatedField.type === FormFieldTypes.Address) {
            updatedField.cityJsonPath = updateIndexedJsonpath(updatedField.cityJsonPath, f.listIndex, newIndex)
            updatedField.countryJsonPath = updateIndexedJsonpath(updatedField.countryJsonPath, f.listIndex, newIndex)
            updatedField.postalCodeJsonPath = updateIndexedJsonpath(
              updatedField.postalCodeJsonPath,
              f.listIndex,
              newIndex,
            )
            updatedField.streetAddressLine2JsonPath = updateIndexedJsonpath(
              updatedField.streetAddressLine2JsonPath,
              f.listIndex,
              newIndex,
            )
            updatedField.streetAddressJsonPath = updateIndexedJsonpath(
              updatedField.streetAddressJsonPath,
              f.listIndex,
              newIndex,
            )
            updatedField.stateProvinceJsonPath = updateIndexedJsonpath(
              updatedField.stateProvinceJsonPath,
              f.listIndex,
              newIndex,
            )
          }
        }

        return updatedField
      }
      return f
    })

    const updatedFieldsSOT = filteredFieldsSOT.map(f =>
      f.listIndex > index ? { ...f, listIndex: f.listIndex - 1 } : f,
    )

    setFieldPagesSOT({
      ...fieldPagesSOT,
      [activePage.guid]: [...updatedFieldsSOT],
    })
    setFieldPages({ ...fieldPages, [activePage.guid]: updatedFields })
    setFormFields(updatedFields)
  }

  const scrollFieldIntoView = (fieldId: string) => {
    let fieldElement = document.getElementById(`formfield-${fieldId}`)

    if (!fieldElement) {
      fieldElement = document.querySelector('[name="' + fieldId + '"]')
    }

    if (fieldElement && getComputedStyle(fieldElement).visibility !== 'hidden') {
      fieldElement.scrollIntoView({ behavior: 'smooth', block: 'center' })
    } else {
      const associatedPage = FormRendererUtils.findPageGuidByFieldGuid(fieldId, fieldPages)
      if (associatedPage) {
        setPage(associatedPage)
        setFormFields(fieldPages[associatedPage] ?? [])
        setTimeout(() => {
          scrollFieldIntoView(fieldId)
        }, 200)
      } else {
        console.warn(`Element not found: ${fieldId}`)
      }
    }
  }

  return (
    <FormStateContext.Provider
      value={{
        fieldPagesSOT,
        setFieldPagesSOT,
        fieldPages,
        setFieldPages,
        formFields,
        setFormFields,
        latestFormData,
        page,
        setPage,
        validateForm,
        pages,
        formMethods,
        setPages,
        onAddEntryToGroupedPage,
        onRemoveEntryFromGroupedPage,
        isPrintMode,
        setIsPrintMode,
        isReadOnly,
        setIsReadOnly,
        isLoading,
        setIsLoading,
        scrollFieldIntoView,
        sortedPages,
        updateValidationSchema,
        newFields,
        hiddenPageIds,
        setHiddenPageIds,
        fieldPagesLocalRef: FormStateStore.fieldPagesLocalRef,
        onUpdateFormStructure,
        conditions,
        setConditions,
      }}
    >
      {children}
    </FormStateContext.Provider>
  )
}

export const useFormState = () => {
  const context = useContext(FormStateContext)
  if (context === undefined) {
    throw new Error('useFormState must be used within a FormStateProvider')
  }
  return context
}
